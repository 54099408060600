import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

let overlay;
let runningKeydownHandler = false;
let scrollContainer;

const settings = {
    slctrOverlay: "[data-overlay]",
    slctrScrollContainer: "[data-overlay-container]",
};

const keydownHandler = (event) => {
    if (!runningKeydownHandler) {
        runningKeydownHandler = true;
        let isEscape = false;
        if ("key" in event) {
            isEscape = event.key === "Escape" || event.key === "Esc";
        } else {
            isEscape = event.keyCode === 27;
        }
        if (isEscape) {
            overlayClose();
            runningKeydownHandler = false;
        }
    }
};

const UpdateState = (state, selector, property) => {
    const els = document.querySelectorAll(selector);

    els.forEach((el) => {
        const styles = el.dataset[property];
        const classes = JSON.parse(styles || "{}");

        const openArr = classes.open.split(" ");
        const closedArr = classes.closed.split(" ");

        if (state === "open") {
            el.classList.add(...openArr);
            el.classList.remove(...closedArr);
        } else if (state === "closed") {
            el.classList.add(...closedArr);
            el.classList.remove(...openArr);
        }
    });
};

const OpenOverlay = () => {
    UpdateState("open", "[data-overlay-modifiers]", "overlayModifiers");
    disableBodyScroll(scrollContainer);
    window.addEventListener("keydown", keydownHandler, true);
};

const CloseOverlay = () => {
    UpdateState("closed", "[data-overlay-modifiers]", "overlayModifiers");
    enableBodyScroll(scrollContainer);
    window.removeEventListener("keydown", keydownHandler, true);
};

const IsOverlayOpen = () => {
    if (overlay.classList.contains("visible")) {
        return true;
    } else {
        return false;
    }
};

const Overlay = () => {
    overlay = document.querySelector(settings.slctrOverlay);
    if (overlay === null) {
        return;
    }

    overlay = document.querySelector(settings.slctrOverlay);
    scrollContainer = overlay.querySelector(settings.slctrScrollContainer);
};

export { Overlay, IsOverlayOpen, OpenOverlay, CloseOverlay, UpdateState };
