const settings = {
    container: ".js-accordion",
    slctrTrigger: ".js-accordion-trigger",
    slctrMenu: ".accordion-item__body",
    clsOpen: "is-open",
};

const updateAccordionBodyHeight = (menus) => {
    for (let j = 0; j < menus.length; j += 1) {
        const prevHeight = menus[j].style.height;

        let isClosed = false;
        if (prevHeight === "0px") {
            isClosed = true;
        }

        menus[j].style.height = "auto";
        const newHeight = menus[j].offsetHeight;

        menus[j].style.height = prevHeight;
        setTimeout(() => {
            menus[j].setAttribute("data-height", `${newHeight}px`);
            if (isClosed) {
                menus[j].style.height = 0;
            } else {
                menus[j].style.height = `${newHeight}px`;
            }
        }, 0);
    }
};

const setAccordionBodyHeightOnWindowResize = (menus) => {
    let resizeTimer;
    window.addEventListener("resize", () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            updateAccordionBodyHeight(menus);
        }, 200);
    });
};

const toggleAccordionItem = (targetTrigger, accordionType, triggers, menus) => {
    const targetTriggerIcon = targetTrigger.querySelector(
        "[data-accordion-icon]",
    );

    const targetMenu = targetTrigger.nextElementSibling;

    if (targetMenu.offsetHeight > 0) {
        targetMenu.style.height = 0;
        targetTriggerIcon.firstElementChild.classList.remove("opacity-0");
        return false;
    }

    if (accordionType == "collapse") {
        for (let i = 0; i < triggers.length; i += 1) {
            const el = triggers[i].querySelector("[data-accordion-icon]");
            el.firstElementChild.classList.remove("opacity-0");
        }

        for (let j = 0; j < menus.length; j += 1) {
            menus[j].style.height = 0;
        }
    }

    targetTriggerIcon.firstElementChild.classList.add("opacity-0");
    targetMenu.style.height = targetMenu.getAttribute("data-height");
};

const Accordion = () => {
    const containers = document.querySelectorAll("[data-accordion]");
    if (containers.length === 0) {
        return;
    }

    containers.forEach((container) => {
        const triggers = container.querySelectorAll("[data-accordion-target]");
        const menus = container.querySelectorAll("[data-accordion-content]");
        const accordionType = container.dataset.accordion;

        const triggerClickHandler = (e) => {
            toggleAccordionItem(e, accordionType, triggers, menus);
        };

        const initAccordions = () => {
            menus.forEach((menu) => {
                const height = menu.offsetHeight;
                menu.setAttribute("data-height", `${height}px`);
                menu.style.height = 0;
            });

            triggers.forEach((trigger) => {
                trigger.addEventListener("click", (e) => {
                    toggleAccordionItem(
                        e.currentTarget,
                        accordionType,
                        triggers,
                        menus,
                    );
                });
            });

            setAccordionBodyHeightOnWindowResize(menus);
        };

        initAccordions();
    });
};

export default Accordion;
