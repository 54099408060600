import {
    IsOverlayOpen,
    OpenOverlay,
    CloseOverlay,
    UpdateState,
} from "./_overlay";

import { CloseSearch } from "./_searchOverlay";

let nav;

const isNavOpen = () => {
    if (nav.classList.contains("visible")) {
        return true;
    } else {
        return false;
    }
};

const openNav = () => {
    UpdateState("open", "[data-nav-modifiers]", "navModifiers");
};

const CloseNav = () => {
    UpdateState("closed", "[data-nav-modifiers]", "navModifiers");
};

const toggleNav = () => {
    if (IsOverlayOpen() === true && isNavOpen() === true) {
        CloseNav();
        CloseOverlay();
    } else if (IsOverlayOpen() === true && isNavOpen() === false) {
        CloseSearch();
        openNav();
    } else {
        openNav();
        OpenOverlay();
    }
};

const NavDrawer = () => {
    nav = document.querySelector("[data-nav]");
    if (nav === null) {
        return;
    }

    const btns = document.querySelectorAll("[data-nav-trigger]");
    btns.forEach((btn) => {
        btn.addEventListener("click", toggleNav, false);
    });
};

export { NavDrawer, CloseNav };
