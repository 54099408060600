const Favicon = () => {

  const faviconEl = document.querySelector('link[rel="icon"]');
  if(!faviconEl) return;

  function changeFavicon(src) {
    faviconEl.href = src;
  }

  window.addEventListener('blur', function() {
    changeFavicon('/assets/front/img/favicon-inactive.png?v=2024');
  });
  window.addEventListener('focus', function() {
    changeFavicon('/assets/front/img/favicon.png?v=2024');
  });

}

export default Favicon
