const inputMinLength = 3;

const isValid = (input) => {
    if (input.value.length >= inputMinLength) {
        return true;
    } else {
        return false;
    }
};
const removeError = (input, inputError, submitButton) => {
    inputError.textContent = "";
    inputError.classList.add("hidden");
    input.parentElement.classList.remove(
        "border-orange-900",
        "focus:border-orange-900",
    );
    input.parentElement.classList.add("border-black-400", "focus:border-white");
    submitButton.removeAttribute("disabled");
};

const showError = (input, inputError, submitButton) => {
    inputError.textContent = `Please enter a search term with at least ${inputMinLength} characters`;
    inputError.classList.remove("hidden");
    input.parentElement.classList.remove(
        "border-black-400",
        "focus:border-white",
    );
    input.parentElement.classList.add(
        "border-orange-900",
        "focus:border-orange-900",
    );
    submitButton.setAttribute("disabled", true);
};

const inputHandler = (input, inputError, submitButton) => {
    if (isValid(input)) {
        removeError(input, inputError, submitButton);
    } else {
        showError(input, inputError, submitButton);
    }
};

const focusoutHandler = (input) => {
    if (input.value === "") {
        removeError();
    }
};

const SearchForm = () => {
    const containers = document.querySelectorAll("[data-searchform]");
    if (containers.length === 0) {
        return;
    }

    containers.forEach((container) => {
        const form = container.querySelector("form");
        const input = container.querySelector("[data-searchform-input]");
        const submitButton = container.querySelector(
            "[data-searchform-submit]",
        );

        const inputError = document.createElement("div");
        inputError.setAttribute(
            "class",
            "absolute text-orange-900 mt-3 hidden",
        );
        container.appendChild(inputError);

        form.addEventListener("submit", (event) => {
            if (!isValid(input)) {
                event.preventDefault();

                showError(input, inputError, submitButton);
                input.addEventListener("input", () => {
                    inputHandler(input, inputError, submitButton);
                });
                input.addEventListener("focusout", () => {
                    focusoutHandler(input, inputError, submitButton);
                });
            }
        });
    });
};

export default SearchForm;
