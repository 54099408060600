import { UpdateState } from "./_overlay";

const Navbar = () => {
    const headerToolbar = document.querySelector("[data-header-toolbar]");

    const observer = new IntersectionObserver(
        function (entries, observer) {
            for (let entry of entries) {
                if (entry.isIntersecting === true) {
                    UpdateState(
                        "closed",
                        "[data-intersect-modifiers]",
                        "intersectModifiers",
                    );
                } else {
                    UpdateState(
                        "open",
                        "[data-intersect-modifiers]",
                        "intersectModifiers",
                    );
                }
            }
        },
        { rootMargin: "100px 0px 0px 0px" },
    );
    observer.observe(headerToolbar);
};

export default Navbar;
