const removeError = (email, emailError, submitButton) => {
  emailError.textContent = "";
  emailError.classList.add("hidden");
  email.parentElement.classList.remove(
    "border-orange-900",
    "focus:border-orange-900",
  );
  email.parentElement.classList.add("border-black-400", "focus:border-white");
  submitButton.removeAttribute("disabled");
};

const showError = (email, emailError, submitButton) => {
  if (email.validity.valueMissing) {
    emailError.textContent = "Please enter your email address";
  } else if (email.validity.typeMismatch) {
    emailError.textContent = "Please enter a valid email address";
  } else {
    emailError.textContent = "An error occurred, please try again";
  }

  emailError.classList.remove("hidden");
  email.parentElement.classList.remove(
    "border-black-400",
    "focus:border-white",
  );
  email.parentElement.classList.add(
    "border-orange-900",
    "focus:border-orange-900",
  );
  submitButton.setAttribute("disabled", true);
};

const inputHandler = (email, emailError, submitButton) => {
  if (email.validity.valid) {
    removeError(email, emailError, submitButton);
  } else {
    showError(email, emailError, submitButton);
  }
};

const focusoutHandler = (email) => {
  if (email.value === "") {
    removeError();
  }
};

const NewsletterForm = () => {
  const containers = document.querySelectorAll("[data-newsletter]");
  if (containers.length === 0) {
    return;
  }

  containers.forEach((container) => {
    const form = container.querySelector("form");
    const callback = container.querySelector("[data-newsletter-callback]");
    const email = container.querySelector("[data-newsletter-input]");
    const submitButton = container.querySelector(
      "[data-newsletter-submit]",
    );
    let submitting = false;
    let inputHandlersAdded = false;

    const emailError = document.createElement("div");
    emailError.setAttribute(
      "class",
      "absolute text-orange-900 mt-3 hidden",
    );
    container.appendChild(emailError);

    const submitForm = () => {
      submitting = true;
      const turnstileResponse = container.querySelector('[name="cf-turnstile-response"]');
      const data = "email=" + encodeURIComponent(email.value) + "&cf-turnstile-response=" + encodeURIComponent(turnstileResponse.value);
      const url = form.getAttribute("action");
      const request = new XMLHttpRequest();
      request.open("POST", url, true);
      request.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded; charset=UTF-8",
      );
      request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      request.onload = function() {
        submitting = false;
        if (request.status === 200) {
          switchToSuccessState();
        } else {
          switchToErrorState();
        }
      }
      request.onerror = function() {
        submitting = false;
        switchToErrorState();
      }
      request.send(data)
    }

    const switchToSuccessState = () => {
      form.classList.add("hidden");
      callback.classList.remove("hidden");
      callback.classList.add("flex");
    }

    const switchToErrorState = () => {
      showError(email, emailError, submitButton);
      if(!inputHandlersAdded) {
        email.addEventListener("input", () => {
          inputHandler(email, emailError, submitButton);
        });
        email.addEventListener("focusout", () => {
          focusoutHandler(email, emailError, submitButton);
        });
      }
    }

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      if(submitting) { return; }
      if (!email.validity.valid) {
        switchToErrorState();
      } else {
        event.preventDefault();
        submitForm();
      }
    });
  });
};

export default NewsletterForm;
