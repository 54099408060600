import { UpdateState } from "./_overlay";

const HeaderLogo = () => {
    const content = document.querySelector("[data-content]");

    const sentinel = document.createElement("div");
    sentinel.setAttribute("class", "sentinel");
    content.prepend(sentinel);

    const observer = new IntersectionObserver(
        function (entries, observer) {
            for (let entry of entries) {
                if (window.innerWidth < 1280) {
                    if (entry.isIntersecting) {
                        UpdateState(
                            "closed",
                            "[data-logo-modifiers]",
                            "logoModifiers",
                        );
                    } else {
                        UpdateState(
                            "open",
                            "[data-logo-modifiers]",
                            "logoModifiers",
                        );
                    }
                }
            }
        },
        {
            threshold: [0, 1],
        },
    );
    observer.observe(sentinel);
};

export default HeaderLogo;
