const tabDefaultStyles = ["text-black-900"];
const tabActiveStyles = ["bg-black-900", "text-white"];

const updateTabBtns = (tabBtns, targetTab) => {
    tabBtns.forEach((btn) => {
        if (btn.dataset.tabsTarget === targetTab) {
            btn.classList.remove(...tabDefaultStyles);
            btn.classList.add(...tabActiveStyles);
            btn.ariaSelected = "true";
        } else {
            btn.classList.add(...tabDefaultStyles);
            btn.classList.remove(...tabActiveStyles);
            btn.ariaSelected = "false";
        }
    });
};

const updateTabPanels = (tabPanels, targetTab) => {
    tabPanels.forEach((panel) => {
        if (panel.id === targetTab) {
            panel.classList.remove("hidden");
        } else {
            panel.classList.add("hidden");
        }
    });
};

const updateTabs = (tabBtns, tabPanels, targetTab) => {
    updateTabBtns(tabBtns, targetTab);
    updateTabPanels(tabPanels, targetTab);
};

const Tabs = () => {
    const containers = document.querySelectorAll("[data-tabs-toggle]");
    if (containers.length === 0) {
        return;
    }

    containers.forEach((container) => {
        const tabBtns = container.querySelectorAll('[role="tab"]');
        const tabContent = document.querySelector(container.dataset.tabsToggle);
        const tabPanels = tabContent.querySelectorAll('[role="tabpanel"]');

        tabBtns.forEach((tabBtn, i) => {
            // Show the first tab as active.
            if (i === 0) {
                updateTabs(tabBtns, tabPanels, tabBtn.dataset.tabsTarget);
            }

            tabBtn.addEventListener("click", (e) => {
                e.preventDefault();
                updateTabs(tabBtns, tabPanels, tabBtn.dataset.tabsTarget);
            });
        });
    });
};

export default Tabs;
