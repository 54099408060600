import Swiper from "swiper";
import { Keyboard, Navigation } from "swiper/modules";

const settings = {
    container: ".js-carousel",
};

const Carousel = () => {
    const containers = document.querySelectorAll(settings.container);
    if (containers.length === 0) {
        return;
    }

    containers.forEach((container, i) => {
        const { options } = container.dataset;
        const o = JSON.parse(options || "{}");

        const trackEl = container.querySelector(".js-carousel-track");
        const prevEl = container.querySelector(".js-carousel-prev");
        const nextEl = container.querySelector(".js-carousel-next");

        trackEl.classList.add(`js-carousel-${i}`);
        prevEl.classList.add(`js-carousel-prev-${i}`);
        nextEl.classList.add(`js-carousel-next-${i}`);

        const slider = new Swiper(`.js-carousel-${i}`, {
            spaceBetween: 24,
            grabCursor: true,
            lazy: true,
            keyboard: {
                enabled: true,
            },
            modules: [Keyboard, Navigation],
            navigation: {
                nextEl: `.js-carousel-next-${i}`,
                prevEl: `.js-carousel-prev-${i}`,
            },
            ...o,
        });
    });
};

export default Carousel;
