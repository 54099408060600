import hoverintent from "hoverintent";

const Intent = () => {
    const containers = document.querySelectorAll("[data-intent]");
    if (containers.length === 0) {
        return;
    }

    containers.forEach((container) => {
        const styles = container.dataset.intent;
        const classes = JSON.parse(styles || "{}");

        const handlerInStyles = classes.in.split(" ");
        const handlerOutStyles = classes.out.split(" ");

        const arrowSvg = container.previousElementSibling.querySelector("svg");
        const arrowStyles = arrowSvg.dataset.intentArrow;
        const arrowClasses = JSON.parse(arrowStyles || "{}");

        const handlerInArrowStyles = arrowClasses.in.split(" ");
        const handlerOutArrowStyles = arrowClasses.out.split(" ");

        hoverintent(
            container.parentElement,
            () => {
                // Handler in.
                container.classList.add(...handlerInStyles);
                container.classList.remove(...handlerOutStyles);

                arrowSvg.classList.add(...handlerInArrowStyles);
                arrowSvg.classList.remove(...handlerOutArrowStyles);
            },
            () => {
                // Handler out.
                container.classList.add(...handlerOutStyles);
                container.classList.remove(...handlerInStyles);

                arrowSvg.classList.add(...handlerOutArrowStyles);
                arrowSvg.classList.remove(...handlerInArrowStyles);
            },
        ).options({
            interval: 40,
            timeout: 200,
        });
    });
};

export default Intent;
