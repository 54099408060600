import Accordion from "./_accordion";
import Carousel from "./_carousel";
import Eyes from "./_eyes";
import HeaderLogo from "./_headerLogo";
import Intent from "./_intent";
import Navbar from "./_navbar";
import {NavDrawer} from "./_nav";
import NewsletterForm from "./_newsletterForm";
import {Overlay} from "./_overlay";
import SearchForm from "./_searchForm";
import {SearchOverlay} from "./_searchOverlay";
import Tabs from "./_tabs";
import Favicon from "./_favicon.js";

const run = (func) => {
    try {
        func();
    } catch (e) {
        console.error(e);
    }
};

const initApp = () => {
    run(Accordion);
    run(Carousel);
    run(Eyes);
    run(Favicon);
    run(HeaderLogo);
    run(Intent);
    run(Navbar);
    run(NavDrawer);
    run(NewsletterForm);
    run(Overlay);
    run(SearchForm);
    run(SearchOverlay);
    run(Tabs);
};

if (document.readyState === "interactive") {
    initApp();
} else {
    window.addEventListener("DOMContentLoaded", initApp, false);
}
