import {
    IsOverlayOpen,
    OpenOverlay,
    CloseOverlay,
    UpdateState,
} from "./_overlay";

import { CloseNav } from "./_nav";

let input;
let search;

const isSearchOpen = () => {
    if (search.classList.contains("visible")) {
        return true;
    } else {
        return false;
    }
};

const openSearch = () => {
    UpdateState("open", "[data-search-modifiers]", "searchModifiers");
    input.focus();
};

const CloseSearch = () => {
    UpdateState("closed", "[data-search-modifiers]", "searchModifiers");
};

const toggleSearch = () => {
    if (IsOverlayOpen() === true && isSearchOpen() === true) {
        CloseSearch();
        CloseOverlay();
    } else if (IsOverlayOpen() === true && isSearchOpen() === false) {
        CloseNav();
        openSearch();
    } else {
        openSearch();
        OpenOverlay();
    }
};

const SearchOverlay = () => {
    search = document.querySelector("[data-search]");
    if (search === null) {
        return;
    }

    input = search.querySelector("input[type=search]");

    const btns = document.querySelectorAll("[data-search-trigger]");
    btns.forEach((btn) => {
        btn.addEventListener("click", toggleSearch, false);
    });
};

export { SearchOverlay, CloseSearch };
